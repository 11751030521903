'use strict';

var focusHelper = require('base/components/focus');
var Cookies = require('js-cookie');
/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');
    var urlReject = $('.tracking-consent').data('reject');
    var textYes = $('.tracking-consent').data('accepttext');
    var textNo = $('.tracking-consent').data('rejecttext');
    var textHeader = $('.tracking-consent').data('heading');

    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="consent-tracking" aria-modal="true" role="dialog">'
        + '<div class="modal-dialog modal-consent-tracking">'
        + '<!-- Modal content-->'
        + '<div class="modal-content" style="position: fixed; bottom: 0px; left: 0px; width: 100%; border-radius: 0px;">'

        + '<div class="modal-body">'
        + '<div class="notice d-flex justify-content-between align-items-center">'
        + '<div class="cookie-text" style="padding: 15px 0; text-align: center; font-size:1.2rem; margin-left: 40px;"></div>'
        + '<div class="buttons d-flex flex-column flex-lg-row">'
        + '<button class="affirm btn btn-primary" data-url="' + urlAccept + '" autofocus data-dismiss="modal" style="margin: 0.2rem;">'
        + textYes
        + '</button>'
        + '<button class="decline btn btn-outline-primary" data-url="' + urlReject + '" data-dismiss="modal" style="margin: 0.2rem;">'
        + textNo
        + '</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $.spinner().start();
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('#consent-tracking .cookie-text').html(response);
            $('#consent-tracking').modal('show');
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking .buttons button').click(function (e) {
        e.preventDefault();
        var consent = $(this).hasClass('affirm');
        Cookies.set('consent-tracking', consent, { expires: 365 });
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });
}

module.exports = function () {
    var cookieConsentTracking = Cookies.get('consent-tracking');

    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true') && cookieConsentTracking != undefined) {
        var url = cookieConsentTracking == "true" ? $('.tracking-consent').data('accept') : $('.tracking-consent').data('reject');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json'
        });
    }

    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true') && cookieConsentTracking == undefined) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .close').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};