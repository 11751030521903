// Activate back to top button when footer is reached
const backToTop = document.querySelector('.back-to-top');
const backToTopTrigger = document.querySelector('.footer');

const triggerBackToTop = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            // Show button
            backToTop.classList.add('active');
        } else {
            // Hide button
            backToTop.classList.remove('active');
        }
    });
};

const observer = new IntersectionObserver(triggerBackToTop);
if (backToTopTrigger) {
    observer.observe(backToTopTrigger);
}
