// eslint-disable-next-line import/no-extraneous-dependencies
window.jQuery = require('jquery');

window.$ = window.jQuery;

const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('autoship/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('autoship/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('giftcertificate/giftcert/giftcert'));
    processInclude(require('gtm/gtm/init'));
    processInclude(require('googleaddress/components/addressSuggestions'));
    processInclude(require('./components/backToTop'));
});

require('autoship/thirdParty/bootstrap');
require('base/components/spinner');
require('jquery-mask-plugin'); // eslint-disable-line
