const base = require('base/product/base');

base.selectAttribute = () => {
    $(document).off('change', 'select[class*="select-"], .options-select')
        .on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            let $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            base.attributeSelect(e.currentTarget.value, $productContainer);
        });
};

module.exports = base;
