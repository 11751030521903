/*  this file needs to be included on the miniCart.js to be used on the project */
const base = require('site/product/base');
const siteProductDetail = require('site/product/detail');
const focusHelper = require('base/components/focus');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + `</button>${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> '
                + '<div class="col-12 text-center"> '
                + `<h1>${data.resources.emptyCartMsg}</h1> `
                + '</div> '
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems,
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);

    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems,
    });

    if (data.totals.totalTax !== '$0.00' && data.totals.totalTax !== '-') {
        $('.tax-total').empty().append(data.totals.totalTax);
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append(`- ${data.totals.orderLevelDiscountTotal.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append(`- ${data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        } else {
            $(`.item-${item.UUID}`).empty();
        }
        $(`.uuid-${item.UUID} .unit-price`).empty().append(item.renderedPrice);
        $(`.line-item-price-${item.UUID} .unit-price`).empty().append(item.renderedPrice);
        $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + `</button>${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * Clear Cart error notifications
 */
function clearErrorNotification() {
    const cartErrorContent = $('.cart-error');
    if (cartErrorContent) {
        cartErrorContent.empty();
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $(`.availability-${lineItem.UUID}`).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach((message) => {
                    messages += `<p class="line-item-attributes">${message}</p>`;
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += `<p class="line-item-attributes line-item-instock-date">${lineItem.availability.inStockDate}</p>`;
            }
        }

        $(`.availability-${lineItem.UUID}`).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (let i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $(`.card.product-info.uuid-${uuid}`).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html, isGiftCertificate) {
    const $html = $('<div>').append($.parseHTML(html));

    let body;
    if (isGiftCertificate) {
        body = $html.find('.product-gift-certificate');
    } else {
        body = $html.find('.product-quickview');
    }
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl, isGiftCertificate) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate, isGiftCertificate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal .quantity-select').val(data.selectedQuantity);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid, isLoyaltyItem) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('isLoyaltyItem', isLoyaltyItem);
    $productToRemoveSpan.empty().append(productName);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const productName = $(this).data('name');
        const uuid = $(this).data('uuid');
        const isLoyaltyItem = $(this).parents('.product-info').find('.loyalty-reward-requirements').length > 0
            || $(this).parents('.product-info').find('.loyalty-reward-item').length > 0;
        confirmDelete(actionUrl, productID, productName, uuid, isLoyaltyItem);
    });

    $('body').on('afterRemoveFromCart', (e, data) => {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click((e) => {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const isLoyaltyItem = $(this).data('isLoyaltyItem');

        const urlParams = {
            pid: productID,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.basket.items.length === 0 && data.basket.giftCertificateItems.length === 0) {
                    $('.cart').empty().append('<div class="row"> '
                        + '<div class="col-12 text-center"> '
                        + `<h1>${data.basket.resources.emptyCartMsg}</h1> `
                        + '</div> '
                        + '</div>');
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems,
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (let i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                        }
                    }
                    $(`.uuid-${uuid}`).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update', data);
                clearErrorNotification();

                if (isLoyaltyItem) {
                    window.location.reload();
                }

                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
    $('body').on('cart:update:item:quantity', () => {
        const el = window.event.target;
        const parent = $(el).parents('.card.product-info').length > 0 ? $(el).parents('.card.product-info') : $(el).parents('.product-line-item');
        const quantityElement = parent.find('.form-control.quantity.custom-input-number');
        const preSelectQty = quantityElement.data('pre-select-qty');
        const quantity = $(el).data('loyalty-quantity') ? parseInt($(el).data('loyalty-quantity')) + parseInt(quantityElement.val())
            : quantityElement.val();
        const productID = quantityElement.data('pid');
        let url = quantityElement.data('action');
        const uuid = quantityElement.data('uuid');

        const urlParams = {
            pid: productID,
            quantity,
            uuid,
        };
        url = appendToUrl(url, urlParams);

        $(el).parents('.card').spinner().start();

        $.ajax({
            url,
            type: 'get',
            context: el,
            dataType: 'json',
            success(data) {
                $(`.quantity[data-uuid="${uuid}"]`).val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                quantityElement.data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);
                clearErrorNotification();

                $.spinner().stop();

                const hasLoyaltyReward = $(el).parents('.product-info').find('.loyalty-reward-requirements').length > 0;
                if ($(el).data('loyalty-quantity') || hasLoyaltyReward
                    || (quantityElement.parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length)) {
                    window.location.reload();
                }
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    quantityElement.val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on('change', '.quantity-form > .quantity', () => {
        $('body').trigger('cart:update:item:quantity');
    });

    $('body').on('click', '.loyalty-add-to-cart', () => {
        $('body').trigger('cart:update:item:quantity');
    });

    $('.shippingMethods').change(function () {
        const url = $(this).attr('data-actionUrl');
        const urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id'),
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success(data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                clearErrorNotification();
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('.promo-code-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                clearErrorNotification();
                $.spinner().stop();
            },
            error(err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        const couponCode = $(this).data('code');
        const uuid = $(this).data('uuid');
        const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        const $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const couponCode = $(this).data('code');
        const urlParams = {
            code: couponCode,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $(`.coupon-uuid-${uuid}`).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                clearErrorNotification();
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error(err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success(data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            },
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('#checkoutAcknowledgmentModal [role="confirm"]').on('click', function () {
        $(this).closest('.modal').one('hidden.bs.modal', function () {
            window.location.href = $(this).data('bs.modal')._config.url;
        });
    });

    $('body').on('keydown', '#editProductModal', (e) => {
        const focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select',
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'),
        );
    });

    $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            const allAvailable = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('available'));

            const allReady = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('ready-to-order'));

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', (e, response) => {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        const selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        const selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    // handling edit gift certificate click
    $('body').on('click', '.cart-page .gift-card-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl, true);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        const selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        const form = {
            uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId,
        };
        const $checkBox = $(this).parents('.quick-view-dialog').find('.js--autoship-subscription-opted');
        if ($checkBox.prop('checked') && $('.quick-view-dialog').is(':visible')) {
            const $autoshipFrequency = $checkBox.parents('.autoship-section').find('.js--autoshipFrequency');
            if ($autoshipFrequency.length && $autoshipFrequency.val() !== '') {
                form.autoshipFrequency = $autoshipFrequency.val();
            }
        }
        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success(data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(`.uuid-${data.uuidToBeDeleted}`).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);
                    clearErrorNotification();
                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        }
    });

    /**
     * Update the Autoship frequency of ProductLineItem in Cart
     */
    function changeProductAutoShipInterval() {
        const $cartAutoshipFrequency = $('.js--cart-autoship select.js--autoshipFrequency');
        if ($cartAutoshipFrequency.length > 0) {
            $(document).on('change', '.js--cart-autoship select.js--autoshipFrequency', function () {
                $('body').spinner().start();
                const selectedValue = $(this).val();
                const $actionObj = $(this).parents('.js--cart-autoship').find('#updatePLIAutoshipInterval');
                const urlString = $actionObj.attr('data-action');
                const productID = $actionObj.attr('data-pid');
                const uuid = $actionObj.attr('data-uuid');
                let quantity = '';
                if ($(this).closest('.bundle-card-footer').length > 0) {
                    quantity = $(this).closest('.bundle-card-footer').find('.quantity').val();
                } else {
                    quantity = $(this).closest('.product-info').find('.quantity').val();
                }
                const form = {
                    pid: productID,
                    uuid,
                    autoshipFrequency: selectedValue,
                    quantity,
                };

                $.ajax({
                    url: urlString,
                    type: 'post',
                    context: this,
                    dataType: 'json',
                    data: form,
                    success(data) {
                        if (data.success) {
                            window.location.reload();
                        }
                    },
                    error(err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                        $('body').spinner().stop();
                    },
                });
            });

            $(document).on('click', '.js--cart-autoship input[name^="autoshipFrequency"]', function () {
                $('body').spinner().start();
                const selectedValue = $(this).val();
                const $actionObj = $(this).parents('.js--cart-autoship').find('#updatePLIAutoshipInterval');
                const urlString = $actionObj.attr('data-action');
                const productID = $actionObj.attr('data-pid');
                const uuid = $actionObj.attr('data-uuid');
                let quantity = '';

                if ($(this).closest('.bundle-card-footer').length > 0) {
                    quantity = $(this).closest('.bundle-card-footer').find('.quantity').val();
                } else {
                    quantity = $(this).closest('.product-info').find('.quantity').val();
                }
                const form = {
                    pid: productID,
                    uuid,
                    quantity,
                };
                if (selectedValue === 'PurchaseOnce') {
                    form.autoshipFrequency = '';
                } else {
                    form.autoshipFrequency = $(this).parents('.js--cart-autoship').find('select.js--autoshipFrequency').val();
                }

                $.ajax({
                    url: urlString,
                    type: 'post',
                    dataType: 'json',
                    context: this,
                    data: form,
                    success(data) {
                        if (data.success) {
                            window.location.reload();
                            $('body').spinner().stop();
                        }
                    },
                    error(err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                        $('body').spinner().stop();
                    },
                });
            });

            $(document).on('click', '.js--learnMore', () => {
                $('#learnMoreModal').modal('show');
                $('#learnMoreModal').on('shown.bs.modal', () => {
                    $('body').addClass('modal-open');
                });
            });

            $(document).on('click', '.js--learnMoreCloseModal', () => {
                $('#learnMoreModal').modal('hide');
            });
        }
    }

    function redeemReward() {
        $(document).on('click', '.js-loyalty-reward-cart.redeem', function () {
            $.spinner().start();
            const addToCartUrl = $('.add-to-cart-url').val();
            const form = {
                pid: $(this).data('product-id'),
                quantity: $(this).data('quantity'),
                rewardID: $(this).data('reward-id'),
                fromPliUUID: $(this).data('frompliuuid'),
            };
            const formData = new FormData();

            Object.keys(form).forEach((key) => {
                formData.append(key, form[key]);
            });

            fetch(addToCartUrl, {
                method: 'POST',
                body: formData,
            }).then((response) => response.json()).then((data) => {
                if (data.error) {
                    $.spinner().stop();
                    createErrorNotification(data.message);
                    return;
                }

                $('body').trigger('product:afterAddToCart', data);
                window.location.reload();
                $.spinner().stop();
            }).catch(() => {
                $.spinner().stop();
            });
        });
    }

    function rewardProductsModal() {
        $('.loyalty-reward-btn').on('click', function () {
            const url = $('.load-reward-products').val();
            const isCart = $('.is-cart').val();
            const getYProducts = $(this).closest('.loyalty-reward-item').data('loyalty');
            const rewardId = $(this).closest('.loyalty-reward-item').data('reward-id');
            const fromPliUUID = $(this).closest('.loyalty-reward-item').data('frompliuuid');

            $('#rewardListModal').find('.modal-body').html('');
            $('#rewardListModal').modal('show');
            $.spinner().start();

            $.ajax({
                url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                    getYProducts,
                    id: rewardId,
                    fromPliUUID,
                    isCart,
                }),
                success(content) {
                    $('#rewardListModal').find('.modal-body').html(content);
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                    $('#rewardListModal').modal('hide');
                },
            });
        });
    }

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    changeProductAutoShipInterval();
    redeemReward();
    rewardProductsModal();
    siteProductDetail.updateAutoshipPrice();
};
