/* global google */

/**
 * Error render when incomplete address selected from suggestions
 * @param $addressField
 * @returns
 */
function inCompleteAddressSelectionError($addressField) {
    const $form = $addressField.closest('form');
    $form.closest('form').find('input[type=text], select').val('');
    $addressField.focus();
    $addressField.next('.invalid-feedback').show().text('Invalid Address. Please try again.');
}

/**
 *
 * @param $form
 * @param addressDetails
 * @returns
 */
function setAddressDetails($form, addressDetails, $addressField) {
    if (!Object.prototype.hasOwnProperty.call(addressDetails, 'city')) {
        inCompleteAddressSelectionError($addressField);
        return;
    }

    $form.find('[name$="_address1"]').val(addressDetails.streetAddress);
    $form.find('[name$="_city"]').val(addressDetails.city);
    $form.find('[name$="_country"]').val(addressDetails.country);
    $form.find('[name$="_stateCode"]').val(addressDetails.state);
    if (Object.prototype.hasOwnProperty.call(addressDetails, 'postcode')) {
        $form.find('[name$="_postalCode"]').val(addressDetails.postcode.split('-')[0]);
        $form.find('[name$="_postalCode"]').trigger('change');
    } else {
        $form.find('[name$="_postalCode"]').val('');
    }
    $addressField.next('.invalid-feedback').hide().text('');
}

/**
 * Get Address Details from place address component
 * @param place
 * @returns Object
 */
function getAddressDetails(place) {
    const addressDetails = { streetAddress: '' };
    const addressComponents = place.address_components;

    for (let i = 0; i < addressComponents.length; i++) {
        const addressComponent = addressComponents[i];
        const { 0: addressType } = addressComponent.types;
        if (addressType === 'street_number') { addressDetails.streetAddress = `${addressDetails.streetAddress + addressComponent.short_name} `; }
        if (addressType === 'route') { addressDetails.streetAddress = `${addressDetails.streetAddress + addressComponent.long_name} `; }
        if (addressType === 'locality' || addressType === 'sublocality_level_1') { addressDetails.city = addressComponent.long_name; }
        if (addressType === 'administrative_area_level_1') { addressDetails.state = addressComponent.short_name; }
        if (addressType === 'country') { addressDetails.country = addressComponent.short_name; }
        if (addressType === 'postal_code') { addressDetails.postcode = addressComponent.short_name; }
        if (addressType === 'postal_code_suffix') { addressDetails.postcode = `${addressDetails.postcode}-${addressComponent.short_name}`; }
    }

    return addressDetails;
}

/**
 * Initiate Autocomplete apis
 * @param $addressField
 * @returns
 */
function geolocate($addressField) {
    const autocomplete = new google.maps.places.Autocomplete($addressField[0], {
        types: ['geocode'], componentRestrictions: { country: 'us' },
    });

    autocomplete.setFields(['address_components']);
    autocomplete.addListener('place_changed', () => {
        let errContainer = 0;
        let addressDetails = {};
        const place = autocomplete.getPlace();
        const $form = $addressField.closest('form');

        if (typeof place === 'undefined') {
            inCompleteAddressSelectionError($addressField);
            return;
        }

        for (let i = 0; i < place.address_components.length; i++) {
            const addressTypes = place.address_components[i].types;
            if (addressTypes.indexOf('street_number') > -1 || addressTypes.indexOf('route') > -1) {
                errContainer = 0;
                break;
            } else {
                errContainer += 1;
            }
        }

        if (errContainer > 0) {
            errContainer = 0;
            inCompleteAddressSelectionError($addressField);
        } else {
            $addressField.next('.invalid-feedback').hide();
            addressDetails = getAddressDetails(place);
            setAddressDetails($form, addressDetails, $addressField);
        }
    });
}

$(document).ready(() => {
    $(document).trigger('initGoogleAddress');
});

$(document).on('initGoogleAddress', () => {
    if (window.google !== undefined) {
        $('input[name$="_address1"]').on('focus', function () {
            if (!($(this).hasClass('pac-target-input'))) {
                geolocate($(this));
            }
        });
    }
});
