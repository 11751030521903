const cart = require('loyalty/cart/cart');

module.exports = () => {
    cart();

    $('body').on('cart:update', (event, data) => {
        if (!data.isSameDayProduct) {
            const $perishableAlert = $('.cart-totals .same-day-perishable');

            if ($perishableAlert) {
                $perishableAlert.parents('.cart-totals').remove();
            }
        }
    });

    $('.product-info .quantity').on('keydown', (e) => {
        const invalidChars = ['-', '+', 'e', 'E', '.'];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
        return true;
    });
};
