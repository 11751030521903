const baseDetail = require('base/product/detail');
const base = require('site/product/base');
/* autoship START */
const autoshipBase = require('autoship/product/base');

baseDetail.addToCart = autoshipBase.addToCart;
/* autoship END */

/* instorepickup START */

/**
 * Update availability on change event on quantity selector and on store:afterRemoveStoreSelection event.
 * If store has been selected, exit function otherwise proceed to update attributes.
 * @param {Object} element DOM Element.
 */
const updateAvailability = (element) => {
    const searchPID = $(element).closest('.product-detail').attr('data-pid');
    const selectorPrefix = `.product-detail[data-pid="${searchPID}"]`;
    if ($(`${selectorPrefix} .selected-store-with-inventory`).is(':visible')) {
        return;
    }

    let $productContainer = $(element).closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(element).closest('.modal-content').find('.product-quickview');
    }

    if ($('.bundle-items', $productContainer).length === 0) {
        base.attributeSelect(
            $(element).find('option:selected').data('url'),
            $productContainer,
        );
    }
};

/**
 * Registering on change event on quantity selector and on store:afterRemoveStoreSelection event.
 */
baseDetail.availability = () => {
    // $(document).on('change', '.quantity-select', function (e) {
    //     e.preventDefault();
    //     updateAvailability($(this));
    // });
    $(document).on('store:afterRemoveStoreSelection', (e, element) => {
        e.preventDefault();
        updateAvailability(element);
    });
};
/* instorepickup END */

/**
 * Purchase option handler to display or hide content options
 */
baseDetail.purchaseOptionHandler = () => {
    $(document).off('change', 'input[name="purchase-method"]').on('change', 'input[name="purchase-method"]', (e) => {
        $('.custom--select--dropdown').addClass('d-none');
        $(e.target).closest('.form-check').find('.custom--select--dropdown').removeClass('d-none');
        if ($(e.target).attr('id') !== 'freeLocalPickup' && !$('.selected-store-with-inventory').hasClass('display-none')) {
            $('#remove-store-selection').click();
        }
        const $container = $(e.target).closest('.product-detail');
        const available = $container.find('.product-available').val();
        $container.find('.add-to-cart').prop('disabled', $(e.target).attr('id') === 'freeLocalPickup' || !available || available === 'false');
    });

    $(document).off('click', '.purchase-option-container').on('click', '.purchase-option-container', (e) => {
        $(e.target).find('input[type=radio]').prop('checked', true).trigger('change');
    });

    const $selectedInput = $('input[name="purchase-method"]:checked');
    $selectedInput.closest('.form-check').find('.custom--select--dropdown').removeClass('d-none');

    if ($selectedInput.attr('id') === 'freeLocalPickup') {
        $('.add-to-cart').prop('disabled', true);
    }
};

baseDetail.updateAddToCart = () => {
    $('body').on('product:updateAddToCart', (e, response) => {
        // update local add to cart (for sets)
        const disabled = (!response.product.readyToOrder || !response.product.available);
        $('button.add-to-cart', response.$productContainer).attr('disabled', disabled);
        $('.product-available', response.$productContainer).val(!disabled);

        const enable = $('.product-availability').toArray()
            .every((item) => $(item).data('available') && $(item).data('ready-to-order'));
        baseDetail.methods.updateAddToCartEnableDisableOtherElements(!enable);
    });
};

baseDetail.updateAutoshipPrice = () => {
    $(document).off('change', '.product-type-option').on('change', '.product-type-option', (e) => {
        $.spinner().start();
        const autoShipPriceURL = $('.purchase-option-container.autoship-section').data('autoship-price-url');
        const pid = base.getPidValue($(this));// $('.add-to-cart').data('pid');
        const isAutoshipPrice = $(e.target).hasClass('autoship-option');
        const $productContainer = $(this).closest('.set-item').length > 0 ? $(this).closest('.set-item') : $(this).closest('.product-detail');

        fetch(`${autoShipPriceURL}?${new URLSearchParams({
            pid,
            isAutoshipPrice,
        })}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.productPrice) {
                    $('.price', $productContainer).replaceWith(data.productPrice);
                }
                $.spinner().stop();
            })
            .catch(() => $.spinner().stop());
    });
};

baseDetail.allowQuantityNumbers = () => {
    $('.quantity-select').on('keydown', (e) => {
        const invalidChars = ['-', '+', 'e', 'E', '.'];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
        return true;
    });
};

baseDetail.afterAtributeSelect = () => {
    $('body').on('product:afterAttributeSelect', (e, response) => {
        const isAutoshipPrice = $('.product-type-option').length > 0 ? $('.product-type-option:checked').hasClass('autoship-option') : false;

        if (isAutoshipPrice && response.data && response.data.product
            && response.data.product.autoshipPrice) {
            $('.price', response.container).replaceWith(response.data.product.renderedAutoshipPrice);
        }
    });
};

module.exports = baseDetail;
